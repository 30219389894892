import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../../util/reactIntl';

import {
  FieldDateInput,
  FieldDropdown,
  FieldHourInput,
  FieldLocationAutocompleteInput,
  FieldTextInput,
  Form,
  IconArtist,
  IconClock,
  PrimaryButton,
  Tooltip,
} from '../../../../components';
import { BOOKING_LOCATION_TYPES } from '../../../../constants/booking';

import css from './TopbarSearchForm.module.css';
import { createFilterOptions } from '../../../../util/listing';
import { checkIfEventDateIsPossible } from '../../../../util/booking';

const TopbarSearchFormComponent = props => {
  const { appConfig, isMobile, className, desktopInputRoot, intl, ...restOfProps } = props;

  const classes = classNames(css.root, className);

  const bandTypeOptions =
    appConfig?.listing?.listingFields?.find(f => f.key === 'bandType')?.enumOptions || [];

  return (
    <FinalForm
      {...restOfProps}
      render={formRenderProps => {
        const { handleSubmit, values } = formRenderProps;
        const { bandType } = values || {};

        const bandTypeSelected = bandType && bandType.length > 0;
        const bandTypePlaceholder = !bandTypeSelected
          ? intl.formatMessage({ id: 'General.artistType' })
          : intl.formatMessage(
              { id: 'General.artistTypeSelectedMessage' },
              { num: values.bandType?.length }
            );

        return (
          <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="SearchPage">
            <FieldDateInput
              className={css.eventDate}
              name="eventDate"
              id="eventDate"
              placeholderText={intl.formatMessage({ id: 'General.eventDate' })}
              displayFormat="ddd, MMM D yyyy"
              format={v => v}
              verticalSpacing={0}
              isDayBlocked={day => !checkIfEventDateIsPossible(day)}
            />
            <Tooltip
              anchorSelect="#location"
              openEvents={{ mouseenter: true }}
              closeEvents={{ click: true, mouseleave: true, blur: true }}
              trigger={
                <FieldLocationAutocompleteInput
                  id="location"
                  name="location"
                  placeholder={intl.formatMessage({
                    id: 'General.venueLocation',
                  })}
                  types={BOOKING_LOCATION_TYPES}
                  autoComplete="off"
                  className={css.location}
                  rootClassName={css.locationRoot}
                  predictionsAdditionalClassName={css.locationPredictions}
                  usePredictionAddress
                />
              }
            >
              {intl.formatMessage({ id: 'General.venueLocation' })}
            </Tooltip>

            <FieldHourInput
              className={classNames(css.startTime, { [css.startTimeHasValue]: !!values.startTime })}
              id="startTime"
              name="startTime"
              label={<IconClock />}
              placeholder={intl.formatMessage({
                id: 'FieldDateAndTimeInput.startTime',
              })}
            />

            <FieldDropdown
              className={css.bandType}
              dropdownInputClassName={classNames({
                [css.bandTypeSelected]: bandTypeSelected,
              })}
              id="bandType"
              name="bandType"
              options={createFilterOptions(bandTypeOptions, true)}
              label={<IconArtist />}
              placeholder={bandTypePlaceholder}
              searchable={false}
            />

            <FieldTextInput
              type="text"
              id={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              name="keywords"
              autoComplete="off"
              label={<IconArtist />}
              placeholder={intl.formatMessage({ id: 'General.artist' }) + '...'}
              className={css.artistName}
            />

            <div className={css.submit}>
              <PrimaryButton type="submit">
                {intl.formatMessage({ id: 'General.search' })}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

TopbarSearchFormComponent.defaultProps = {
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
